// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-artists-clarkkelleyprice-js": () => import("./../src/pages/artists/clarkkelleyprice.js" /* webpackChunkName: "component---src-pages-artists-clarkkelleyprice-js" */),
  "component---src-pages-artists-dallenlambson-js": () => import("./../src/pages/artists/dallenlambson.js" /* webpackChunkName: "component---src-pages-artists-dallenlambson-js" */),
  "component---src-pages-artists-danballard-js": () => import("./../src/pages/artists/danballard.js" /* webpackChunkName: "component---src-pages-artists-danballard-js" */),
  "component---src-pages-artists-haydenlambson-js": () => import("./../src/pages/artists/haydenlambson.js" /* webpackChunkName: "component---src-pages-artists-haydenlambson-js" */),
  "component---src-pages-artists-joelpilcher-js": () => import("./../src/pages/artists/joelpilcher.js" /* webpackChunkName: "component---src-pages-artists-joelpilcher-js" */),
  "component---src-pages-artists-js": () => import("./../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-artists-manuelmansanarez-js": () => import("./../src/pages/artists/manuelmansanarez.js" /* webpackChunkName: "component---src-pages-artists-manuelmansanarez-js" */),
  "component---src-pages-artists-travissylvester-js": () => import("./../src/pages/artists/travissylvester.js" /* webpackChunkName: "component---src-pages-artists-travissylvester-js" */),
  "component---src-pages-auth-0-callback-js": () => import("./../src/pages/auth0_callback.js" /* webpackChunkName: "component---src-pages-auth-0-callback-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-frames-js": () => import("./../src/pages/frames.js" /* webpackChunkName: "component---src-pages-frames-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

